/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useNavigate } from "react-router-dom";
import React from "react";
import axios from "axios";
const AddLessons = () => {
  let navigate = useNavigate();
  const [teachers, setTeachers] = React.useState([]);
  const [classes, setClasses] = React.useState([]);
  const [name, setName] = React.useState("");
  React.useEffect(() => {
    const token = localStorage.getItem("access_token");
    axios
      .get(
        "https://tuzdev-student-management-system.onrender.com/api/auth/getTeachers",
        {
          headers: { Authorization: "Bearer: " + token },
        }
      )
      .then((res) => {
        setTeachers(
          res.data.data.map((item) => ({ ...item, selected: false }))
        );
      });
    axios
      .get(
        "https://tuzdev-student-management-system.onrender.com/api/classes",
        {
          headers: { Authorization: "Bearer: " + token },
        }
      )
      .then((res) => {
        setClasses(res.data.data.map((item) => ({ ...item, selected: false })));
      });
  }, []);

  const save = () => {
    if (
      name &&
      teachers.filter((item) => item.selected).length > 0 &&
      classes.filter((item) => item.selected).length > 0
    ) {
      const token = localStorage.getItem("access_token");
      axios
        .post(
          "https://tuzdev-student-management-system.onrender.com/api/lessons",
          {
            name,
            teacher_ids: teachers
              .filter((item) => item?.selected)
              .map((item) => item?._id),
            class_ids: classes
              .filter((item) => item?.selected)
              .map((item) => item?._id),
          },
          { headers: { Authorization: "Bearer: " + token } }
        )
        .then((res) => navigate("/admin/lessons"));
    } else {
      alert("Tüm değerleri doldurunuz");
    }
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Ders Ekle</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={save} size="sm">
                      Kaydet
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Ders Adı
                          </label>
                          <Input
                            className="form-control-student-name"
                            id="input-username"
                            placeholder="Ders Adı"
                            type="text"
                            onChange={(e) => setName(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <hr className="my-3" />
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Eğitmenler
                    </label>
                    <Row>
                      {console.log(teachers)}
                      <Col xs="8">
                        {teachers.map((item, index) => (
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              onChange={(e) => {
                                setTeachers((prev) => {
                                  let newArr = [...prev];
                                  newArr[index] = {
                                    ...newArr[index],
                                    selected: !item?.selected,
                                  };
                                  return newArr;
                                });
                              }}
                            />
                            <Label check>
                              {item.name + " " + item.surname}
                            </Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>

                    <hr className="my-3" />
                    <label
                      className="form-control-label"
                      htmlFor="input-username"
                    >
                      Verilen Sınıflar
                    </label>
                    <Row>
                      <Col xs="8">
                        {classes.map((item, index) => (
                          <FormGroup check inline>
                            <Input
                              type="checkbox"
                              onChange={(e) => {
                                setClasses((prev) => {
                                  let newArr = [...prev];
                                  newArr[index] = {
                                    ...newArr[index],
                                    selected: !item?.selected,
                                  };
                                  return newArr;
                                });
                              }}
                            />
                            <Label check>{item.name}</Label>
                          </FormGroup>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddLessons;
