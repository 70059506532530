/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  Media,
  Progress,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useNavigate } from "react-router-dom";
import React from "react";
import axios from "axios";
const Classes = () => {
  let navigate = useNavigate();

  const [classes, setClasses] = React.useState([]);

  React.useEffect(() => {
    const token = localStorage.getItem("access_token");
    axios
      .get(
        "https://tuzdev-student-management-system.onrender.com/api/classes",
        { headers: { Authorization: "Bearer: " + token } }
      )
      .then((res) => setClasses(res.data.data));
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="col text-right">
          <Button
            color="default"
            onClick={(e) => {
              navigate("/admin/add-classes");
            }}
            size="xl"
            style={{ marginBottom: 20 }}
          >
            Sınıf Ekle
          </Button>
        </div>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Sınıflar</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Sınıf Adı</th>
                    {/*
                    <th scope="col">Mevcut</th>
                    <th scope="col">Doluluk Oranı</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {classes.map((item) => (
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">{item.name}</span>
                          </Media>
                        </Media>
                      </th>
                      {/*<td>12</td>

                      <td>
                        <div className="d-flex align-items-center">
                          <span className="mr-2">100%</span>
                          <div>
                            <Progress
                              max="100"
                              value="100"
                              barClassName="bg-danger"
                            />
                          </div>
                        </div>
                      </td>*/}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Classes;
