/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Students = () => {
  let navigate = useNavigate();
  const [students, setStudents] = React.useState([]);

  React.useEffect(() => {
    getAllStudents();
  }, []);
  const getAllStudents = () => {
    const token = localStorage.getItem("access_token");
    axios
      .get(
        "https://tuzdev-student-management-system.onrender.com/api/students",
        { headers: { Authorization: "Bearer: " + token } }
      )
      .then((res) => setStudents(res.data.data));
  };
  const deleteStudent = (id) => {
    const token = localStorage.getItem("access_token");
    axios
      .post(
        "https://tuzdev-student-management-system.onrender.com/api/students/delete",
        { id },
        { headers: { Authorization: "Bearer: " + token } }
      )
      .then((res) => getAllStudents());
  };
  const getReport = (id) => {
    window.open(
      `https://tuzdev-student-management-system.onrender.com/api/students/report/${id}`,
      "_blank"
    );
  };
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="col text-right">
          <Button
            color="default"
            onClick={(e) => {
              navigate("/admin/add-students");
            }}
            size="xl"
            style={{ marginBottom: 20 }}
          >
            Öğrenci Ekle
          </Button>
        </div>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Öğrenciler</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Öğrenci Adı</th>
                    <th scope="col">Sınıfı</th>
                    <th scope="col">Not Girilen Ders</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {students.map((item) => (
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">{item?.name}</span>
                          </Media>
                        </Media>
                      </th>
                      <td>{item.class_id.name}</td>
                      <td>{item.note.length}</td>

                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={() => deleteStudent(item?._id)}
                            >
                              Öğrenciyi Sil
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                navigate("/admin/reports", {
                                  state: {
                                    item: item,
                                  },
                                });
                              }}
                            >
                              Notları Gör
                            </DropdownItem>
                            <DropdownItem onClick={() => getReport(item?._id)}>
                              Karne Oluştur
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Students;
