/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useNavigate } from "react-router-dom";
import React from "react";
import axios from "axios";

const AddTeacher = () => {
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  let navigate = useNavigate();

  const save = () => {
    if (!!name && !!surname && !!password & !!email) {
      const token = localStorage.getItem("access_token");
      axios
        .post(
          "https://tuzdev-student-management-system.onrender.com/api/auth/addTeacher",
          { name, surname, password, email },
          { headers: { Authorization: "Bearer: " + token } }
        )
        .then((res) => {
          navigate("/admin/teachers");
        })
        .catch((e) => {
          if (e.response.data.message === "Duplicate Key") {
            alert("Mail adresi kullanılıyor.");
          }
          console.log(e);
        });
    } else {
      alert("Boş olan alanları doldurunuz.");
    }
  };
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Eğitmen Ekle</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={save} size="sm">
                      Kaydet
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            İsim
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="İsim"
                            type="text"
                            onChange={(e) => setName(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Soyisim
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Soyisim"
                            type="text"
                            onChange={(e) => setSurname(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="jesse@example.com"
                            type="email"
                            onChange={(e) => setEmail(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Şifre
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Şifre"
                            type="password"
                            onChange={(e) => setPassword(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddTeacher;
