/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Media,
  Badge,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

const AddNote = () => {
  let navigate = useNavigate();
  const { state } = useLocation();
  const [gain1Name, setGain1Name] = React.useState("");
  const [gain2Name, setGain2Name] = React.useState("");
  const [gain3Name, setGain3Name] = React.useState("");
  const [gain4Name, setGain4Name] = React.useState("");
  const [gain5Name, setGain5Name] = React.useState("");
  const [gain6Name, setGain6Name] = React.useState("");
  const [gain7Name, setGain7Name] = React.useState("");
  const [gain8Name, setGain8Name] = React.useState("");

  const [gain1, setGain1] = React.useState(0);
  const [gain2, setGain2] = React.useState(0);
  const [gain3, setGain3] = React.useState(0);
  const [gain4, setGain4] = React.useState(0);
  const [gain5, setGain5] = React.useState(0);
  const [gain6, setGain6] = React.useState(0);
  const [gain7, setGain7] = React.useState(0);
  const [gain8, setGain8] = React.useState(0);

  const [ozduzenleme, setOzduzenleme] = React.useState(0);
  const [zaman, setZaman] = React.useState(0);
  const [coklugorev, setCoklugorev] = React.useState(0);
  const [katilim, setKatilim] = React.useState(0);
  const [motivasyon, setMotivasyon] = React.useState(0);
  const [ifade, setIfade] = React.useState(0);
  const [liderlik, setLiderlik] = React.useState(0);
  const [yaraticilik, setYaraticilik] = React.useState(0);
  const [sayginlik, setSayginlik] = React.useState(0);

  React.useEffect(() => {
    if (state.gains && state.gains.length > 7) {
      setGain1Name(state.gains[0]?.name);
      setGain2Name(state.gains[1]?.name);
      setGain3Name(state.gains[2]?.name);
      setGain4Name(state.gains[3]?.name);
      setGain5Name(state.gains[4]?.name);
      setGain6Name(state.gains[5]?.name);
      setGain7Name(state.gains[6]?.name);
      setGain8Name(state.gains[7]?.name);
    }
  }, []);
  React.useEffect(() => {
    if (state.notes) {
      setGain1(state.notes[0]?.note);
      setGain2(state.notes[1]?.note);
      setGain3(state.notes[2]?.note);
      setGain4(state.notes[3]?.note);
      setGain5(state.notes[4]?.note);
      setGain6(state.notes[5]?.note);
      setGain7(state.notes[6]?.note);
      setGain8(state.notes[7]?.note);
      setOzduzenleme(state.notes[8]?.note);
      setZaman(state.notes[9]?.note);
      setCoklugorev(state.notes[10]?.note);
      setKatilim(state.notes[11]?.note);
      setMotivasyon(state.notes[12]?.note);
      setIfade(state.notes[13]?.note);
      setLiderlik(state.notes[14]?.note);
      setYaraticilik(state.notes[15]?.note);
      setSayginlik(state.notes[16]?.note);
    }
  }, []);

  const save = () => {
    if (
      gain1 &&
      gain2 &&
      gain3 &&
      gain4 &&
      gain5 &&
      gain6 &&
      gain7 &&
      gain8 &&
      gain1Name &&
      gain2Name &&
      gain3Name &&
      gain4Name &&
      gain5Name &&
      gain6Name &&
      gain7Name &&
      gain8Name &&
      ozduzenleme &&
      zaman &&
      coklugorev &&
      katilim &&
      motivasyon &&
      ifade &&
      liderlik &&
      yaraticilik &&
      sayginlik &&
      state?.id
    ) {
      const token = localStorage.getItem("access_token");
      axios
        .post(
          "https://tuzdev-student-management-system.onrender.com/api/students/add_note",
          {
            id: state?.id,
            note: [
              { name: gain1Name, note: gain1 },
              { name: gain2Name, note: gain2 },
              { name: gain3Name, note: gain3 },
              { name: gain4Name, note: gain4 },
              { name: gain5Name, note: gain5 },
              { name: gain6Name, note: gain6 },
              { name: gain7Name, note: gain7 },
              { name: gain8Name, note: gain8 },
              { name: "Özdüzenleme", note: ozduzenleme },
              { name: "Zaman Farkındalığı", note: zaman },
              { name: "Çoklu Görev ve Dikkat	", note: coklugorev },
              { name: "Derse Katılım ve Etkinlik Arayışı	", note: katilim },
              { name: "Motivasyon", note: motivasyon },
              { name: "Kendini İfade Etme - Özgüven ", note: ifade },
              { name: "Liderlik", note: liderlik },
              { name: "Yaratıcılık", note: yaraticilik },
              { name: "Saygınlık", note: sayginlik },
            ],
          },
          { headers: { Authorization: "Bearer: " + token } }
        )
        .then((res) => {
          navigate("/teacher/students");
        });
    } else {
      alert("Notlar boş bırakılamaz");
    }
  };

  const KAZANIM = [
    {
      header: "Özdüzenleme",
      value: "Derse kendisini fiziksel, duygusal ve sosyal olarak hazırlar.",
      note: "1: zayıf",
    },
    {
      header: "Zaman Farkındalığı",
      value:
        "Verilen görevleri zamanında tamamlar ve süre içinde etkin çalışır.",
      note: "2: normal altı",
    },
    {
      header: "Çoklu görev ve Dikkat",
      value: "Verilen görevler içinde organize ve dikkatli çalışır.",
      note: "3: normal",
    },
    {
      header: "Derse Katılım ve Etkinlik Arayışı",
      value:
        'Derse katılım düzeyi, (soru-cevap yapabilme) ve "sınıfla" uyum içinde etkinlikleri tamamlayabilme becerisi"',
      note: "4: normal üstü",
    },
    {
      header: "Motivasyon",
      value:
        "Kendi kendine veya öğretmen yardımıyla motivasyonunu ders içerisinde arttırabilir.",
      note: "5: güçlü",
    },
    {
      header: "Kendini İfade Etme - özgüven",
      value:
        "Ders içerisinde sözel ve davranışsal olarak kendisini ifade edebilir.",
      note: "6: fark yaratıyor",
    },
    {
      header: "Liderlik",
      value:
        "Sınıfı etkinliklerde liderlik özellikleri sergiler, insiyatif alır.",
    },
    {
      header: "Yaratıcılık",
      value:
        'Ders içerisinde verilen etkinliklerden yaratıcı -zaman zaman- "fark yaratıcı" ürünler ortaya koyar, sorgular ve yeni keşiflerde bulunur.',
    },
    {
      header: "Saygınlık",
      value: `Ders içerisinde arkadaşlarını ve öğretmenlerini anlaması, yardımcı olması, arkadaşları ve öğretmenleri tarafından sevilmesi. Arkadaşları ve öğretmenleri tarafından fikirlerinin önemsenmesi.`,
    },
  ];
  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <div className="col mb-3">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Kazanımlar </h3>
              </CardHeader>
              <Table className="align-items-center table-flush " responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Genel Kazanımlar</th>
                    <th scope="col">Genel Kazanımlar Açıklama</th>
                    <th scope="col">Notlar</th>
                  </tr>
                </thead>
                <tbody>
                  {KAZANIM.map((item) => (
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">{item?.header}</span>
                          </Media>
                        </Media>
                      </th>
                      <td>{item.value}</td>
                      <td>
                        <Badge color="" className="badge-dot mr-4">
                          {item?.note}
                        </Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{`Kazanımları Düzenle (${state?.name})`}</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={save} size="sm">
                      Kaydet
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Genel Kazanımlar
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Özdüzenleme
                          </label>
                          <Input
                            type="number"
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Özdüzenleme"}
                            value={ozduzenleme}
                            onChange={(e) =>
                              setOzduzenleme(`${e.target.value}`)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Zaman Farkındalığı
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Zaman Farkındalığı"}
                            type="number"
                            value={zaman}
                            onChange={(e) => setZaman(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Çoklu Görev ve Dikkat
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Çoklu Görev ve Dikkat	"}
                            type="number"
                            value={coklugorev}
                            onChange={(e) => setCoklugorev(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Derse Katılım ve Etkinlik Arayışı
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Derse Katılım ve Etkinlik Arayışı"}
                            type="number"
                            value={katilim}
                            onChange={(e) => setKatilim(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Motivasyon
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Motivasyon"}
                            type="number"
                            value={motivasyon}
                            onChange={(e) => setMotivasyon(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kendini İfade Etme - Özgüven
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Kendini İfade Etme -  Özgüven"}
                            type="number"
                            value={ifade}
                            onChange={(e) => setIfade(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Liderlik
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Liderlik"}
                            type="number"
                            value={liderlik}
                            onChange={(e) => setLiderlik(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Yaratıcılık
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Yaratıcılık"}
                            type="number"
                            value={yaraticilik}
                            onChange={(e) =>
                              setYaraticilik(`${e.target.value}`)
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Saygınlık
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={"Saygınlık"}
                            type="number"
                            value={sayginlik}
                            onChange={(e) => setSayginlik(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Atölye Kazanımları
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain1Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain1Name}
                            type="number"
                            value={gain1}
                            onChange={(e) => setGain1(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain2Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain2Name}
                            type="number"
                            value={gain2}
                            onChange={(e) => setGain2(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain3Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain3Name}
                            type="number"
                            value={gain3}
                            onChange={(e) => setGain3(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain4Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain4Name}
                            type="number"
                            value={gain4}
                            onChange={(e) => setGain4(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain5Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain5Name}
                            type="number"
                            value={gain5}
                            onChange={(e) => setGain5(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain6Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain6Name}
                            type="number"
                            value={gain6}
                            onChange={(e) => setGain6(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain7Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain7Name}
                            type="number"
                            value={gain7}
                            onChange={(e) => setGain7(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {gain8Name}
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder={gain8Name}
                            type="number"
                            value={gain8}
                            onChange={(e) => setGain8(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddNote;
