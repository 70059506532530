/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const StudentsTeacher = () => {
  let navigate = useNavigate();

  const [students, setStudents] = React.useState([]);
  const [lesson, setLesson] = React.useState();

  React.useEffect(() => {
    const token = localStorage.getItem("access_token");
    axios
      .get(
        "https://tuzdev-student-management-system.onrender.com/api/students/teacher",
        {
          headers: { Authorization: "Bearer: " + token },
        }
      )
      .then((res) => setStudents(res.data.data));
    axios
      .get(
        "https://tuzdev-student-management-system.onrender.com/api/lessons/teacher",
        {
          headers: { Authorization: "Bearer: " + token },
        }
      )
      .then((res) => setLesson(res.data.data[0]));
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Öğrenciler</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Öğrenci Adı</th>
                    <th scope="col">Sınıfı</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {students.map((item) => (
                    <tr
                      style={{
                        backgroundColor:
                          item?.note.filter((i) => i.lesson_id === lesson?._id)
                            .length > 0
                            ? "#fff"
                            : "#fcd7d7",
                      }}
                    >
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span
                              style={{ maxWidth: 100 }}
                              className="mb-0 text-sm"
                            >
                              {item?.name}
                            </span>
                          </Media>
                        </Media>
                      </th>
                      <td>{item.class_id.name}</td>

                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={() => {
                                if (
                                  lesson &&
                                  lesson.gain &&
                                  lesson.gain.length > 0
                                ) {
                                  navigate("/teacher/addNote", {
                                    state: {
                                      id: item?._id,
                                      gains: lesson.gain,
                                      name: item?.name,
                                      class_id: lesson?._id,
                                      notes:
                                        item.note.filter(
                                          (item) =>
                                            item.lesson_id === lesson._id
                                        ).length > 0
                                          ? item.note.filter(
                                              (item) =>
                                                item.lesson_id === lesson._id
                                            )[0]?.notes
                                          : [],
                                    },
                                  });
                                } else {
                                  alert("Lütfen önce kazanımları giriniz.");
                                }
                              }}
                            >
                              Notları Düzenle
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default StudentsTeacher;
