/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Button,
} from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const Lessons = () => {
  let navigate = useNavigate();
  const [lessons, setLessons] = React.useState([]);

  React.useEffect(() => {
    const token = localStorage.getItem("access_token");
    axios
      .get(
        "https://tuzdev-student-management-system.onrender.com/api/lessons",
        { headers: { Authorization: "Bearer: " + token } }
      )
      .then((res) => setLessons(res.data.data));
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="col text-right">
          <Button
            color="default"
            onClick={(e) => {
              navigate("/admin/add-lessons");
            }}
            size="xl"
            style={{ marginBottom: 20 }}
          >
            Ders Ekle
          </Button>
        </div>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Dersler</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Ders Adı</th>
                    <th scope="col">Eğitmenler</th>
                    <th scope="col">Girilen Sınıflar</th>
                  </tr>
                </thead>
                <tbody>
                  {lessons.map((item) => (
                    <tr>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">{item.name}</span>
                          </Media>
                        </Media>
                      </th>
                      <td>
                        {item?.teacher_ids.map((item) => (
                          <div className="avatar-group">
                            <div
                              className="avatar avatar-sm"
                              id="tooltip742438047"
                            >
                              {item.name[0] + item.surname[0]}
                            </div>

                            <span className="mb-0 text-sm">
                              {item.name + " " + item.surname}
                            </span>
                          </div>
                        ))}
                      </td>
                      <td>
                        {item?.class_ids.map((item) => item?.name).toString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Lessons;
