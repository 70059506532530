/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useNavigate } from "react-router-dom";
import React from "react";
import axios from "axios";
const AddStudents = () => {
  let navigate = useNavigate();
  const [classes, setClasses] = React.useState([]);
  const [name, setName] = React.useState("");
  const [_class, _setClass] = React.useState("");
  React.useEffect(() => {
    const token = localStorage.getItem("access_token");
    axios
      .get(
        "https://tuzdev-student-management-system.onrender.com/api/classes",
        { headers: { Authorization: "Bearer: " + token } }
      )
      .then((res) => {
        setClasses(res.data.data);
        _setClass(res.data.data[0]?.name);
      });
  }, []);

  const save = () => {
    if (name) {
      const token = localStorage.getItem("access_token");
      axios
        .post(
          "https://tuzdev-student-management-system.onrender.com/api/students",
          {
            name,
            class_id: classes.filter((item) => item?.name === _class)[0]?._id,
          },
          { headers: { Authorization: "Bearer: " + token } }
        )
        .then((res) => navigate("/admin/students"));
    } else {
      alert("Sınıf Adı boş olamaz");
    }
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Öğrenci Ekle</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="primary" onClick={save} size="sm">
                      Kaydet
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Öğrenci Adı Soyadı
                          </label>
                          <Input
                            className="form-control-student-name"
                            id="input-username"
                            placeholder="Öğrenci Adı"
                            type="text"
                            onChange={(e) => setName(`${e.target.value}`)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Sınıfı
                          </label>
                          <Input
                            className="form-control-class"
                            id="input-username"
                            placeholder="Sınıfı"
                            name="select"
                            type="select"
                            onChange={(e) => _setClass(e.target.value)}
                          >
                            {classes.map((item) => (
                              <option>{item?.name}</option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddStudents;
