/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Card, CardHeader, Media, Table, Container, Row } from "reactstrap";
// core components
import Header from "components/Headers/Header.js";
import { useLocation } from "react-router-dom";

const Report = () => {
  const { state } = useLocation();
  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <CardHeader className="border-0 mb-3">
              <h3 className="mb-0">{state.item.name}</h3>
            </CardHeader>
            {state.item.note.map((item) => (
              <Card className="shadow mb-3">
                <CardHeader className="border-0">
                  <h3 className="mb-0">{item.lesson_id?.name}</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Kazanımlar</th>
                      <th scope="col">Notlar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.notes.map((itemIc) => (
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {itemIc.name}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{itemIc.note}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            ))}
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Report;
